import React from "react"
import {Helmet} from 'react-helmet';

import "normalize.css"
import Main from "./main";
import './styles.scss'

const IndexPage = () => {
    return (
        <>
            <Helmet>
                <title>Unisphere metaverse</title>
            </Helmet>
            <div>
                <Main/>
            </div>
        </>
    )
}

export default IndexPage
